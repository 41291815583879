import React from 'react'

export default function Reception() {
    return (
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="14" height="9" stroke="#ff6326 " />
            <path d="M1 1L7.25926 6L14 1" stroke="#ff6326 " />
        </svg>

    )
}
