import React from 'react'

export default function TeamQuote ({quote :{author,quote}}) { 

    function formatQuote (quote) {
        return `"${quote}"`
    }

    return (
        <div className='team--quote'>
            <div className='flex flex-c ju-c'>
                <h5 className='h5 text-center pl30 pr30'>{formatQuote(quote)}</h5>
            </div>
            <div  className='flex flex-c  flex-end'>
                <div className='bt1 p40 text-center'>
                <span className='small-serif orange max-100 '> {author}</span>
                </div>

            </div>
        </div>
    )
}
