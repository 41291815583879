import React from 'react'

export default function Phone() {
    return (
        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.5" y="0.863281" width="11.2727" height="8" transform="rotate(90 8.5 0.863281)" stroke="#ff6326 " />
            <circle cx="4.5" cy="10.5" r="0.5" fill="#ff6326 " />
        </svg>
    )
}
