import { graphql } from 'gatsby'
import React from 'react'
import ContentContainer from '../components/content-container/content-container'
import Layout from '../components/layout'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import SmallSpacer from '../components/spacer/small-spacer'
import Spacer from '../components/spacer/spacer'
import ImageAndContent from '../components/imageAndContent'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import FadeInView from '../components/fade-inview/fade-inview'
import SeniorTeam from '../components/team/senior-team-grid'
import Leadership from '../components/team/leadership-team-grid'
import SubTitle from '../components/scaling-banner/sub-title'

function Svg() {
    return (
        <svg width="984" height="350" viewBox="0 0 984 350" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M35.5189 32.8333V78.0083H80.6939V334H125.869V78.0083H171.044V32.8333H35.5189Z" fill="#EEEAD9" />
            <path d="M434.22 29.8368H338.832V291.687H434.22V252.409H378.109V176.473H430.479V137.195H378.109V69.1143H434.22V29.8368Z" fill="#EEEAD9" />
            <path d="M596.914 253.671H630.279L634.728 219.352H668.729L673.178 253.671H706.543L674.767 31.2342H628.691L596.914 253.671ZM651.57 86.8434L664.28 185.987H638.859L651.57 86.8434Z" fill="#EEEAD9" />
            <path d="M918.677 144.242L898.189 31.1622H866.261V217.411H894.198V120.296L906.172 190.804H931.448L943.421 120.296V217.411H971.359V31.1622H939.43L918.677 144.242Z" fill="#EEEAD9" />
        </svg>

    )
}

export default function Team({ data: { team, seniorTeam, datoCmsTeamPage, datoCmsNewsPage } }) {
    return (
        <Layout>
            <Seo title='Team' />
            <ScalingBanner video={datoCmsTeamPage.videoLink} image={datoCmsTeamPage.heroImage} svg={<Svg />} title='Team' >
                <div className='flex flex-end'>
                    <SubTitle title='Members' />
                </div>
            </ScalingBanner>
            <Spacer />
            <SmallSpacer />
            <ContentContainer>
                <h4 className='ma text-center'>Leadership Team</h4>
                <SmallSpacer />
                <SmallSpacer />
                <SeniorTeam team={seniorTeam.members} quotes={datoCmsTeamPage.teamQuotes} />
                <SmallSpacer />
                <Spacer />
                <h4 className='ma text-center'>Management Team</h4>
                <SmallSpacer />
                <SmallSpacer />
                <Leadership team={team.members} />
            </ContentContainer>
            <Spacer />
            <FadeInView>
                <ImageAndContent phone={datoCmsTeamPage.joinPhone} email={datoCmsTeamPage.joinEmail} image={datoCmsTeamPage.joinImage} title={datoCmsTeamPage.joinTitle} subtitle={datoCmsTeamPage.joinSubtitle} content={datoCmsTeamPage.joinContent} />
            </FadeInView>
            <FadeInView>
                <ImageAndContent video={datoCmsTeamPage.managerVideo} phone={datoCmsTeamPage.managerPhone} email={datoCmsTeamPage.managerEmail} image={datoCmsTeamPage.managerImage} title={datoCmsTeamPage.managerTitle} subtitle={datoCmsTeamPage.managerSubtitle} content={datoCmsTeamPage.managerContent} />
            </FadeInView>
            <SmallSpacer />
            <Spacer />
            <SquareScalingBanner image={datoCmsNewsPage.heroImage} link='/news'>
                <h3 className='small-serif'>Next Page</h3>
                <SmallSpacer />
                <h2 className='h1 m-h3'>News</h2>
            </SquareScalingBanner>
            <Spacer />
        </Layout>
    )
}

export const query = graphql`
    query Team{
        datoCmsNewsPage {
            heroImage {
                gatsbyImageData
            }
        }
        datoCmsTeamPage {
            heroImage {
                gatsbyImageData
                url
            }
            teamQuotes {
                author
                quote
            }
            videoLink
            joinTitle
            joinSubtitle
            joinContent
            joinEmail
            joinPhone
            joinImage {
                gatsbyImageData
                url 
            }
            managerVideo
            managerTitle
            managerSubtitle
            managerContent
            managerEmail
            managerPhone
            managerImage {
                gatsbyImageData
                url 
            }
        }
        seniorTeam:allDatoCmsTeamMember(sort: {fields: position},filter: {senior: {eq: true}}){
            members:nodes{
                ...TeamThumbNail
            }
        }
        team:allDatoCmsTeamMember(sort: {fields: position},filter: {senior: {eq: false}}){
            members:nodes{
                ...GenericTeamMember
            }
        }
    }
`