import React, { memo, useState } from 'react'
import TeamThumbnail from './team-thumbnail'
import TeamQuote from './team-quote'
import TeamSlideIn from './team-slide-in'

function insertElements(array, inserted, pos, every) {
    while (pos < array.length + 1 && inserted.length > 0) {
        array.splice(pos, 0, inserted.shift())
        pos += every
    }

    return array
}

const MixInfoTeam = memo(({ team, quotes, onClick }) => {
    return insertElements([...team], quotes, 3, 4).map((teamMember, index) => {
        if (teamMember?.quote) {
            return <TeamQuote quote={teamMember} />
        } else {
            return <TeamThumbnail onClick={() => onClick(teamMember)} teamMember={teamMember} key={index} />
        }
    })
}, () => true)

export default function SeniorTeam({ team, quotes }) {
    const [teamMember, setTeam] = useState(null)
    return (
        <>
            <TeamSlideIn team={teamMember} close={() => setTeam(null)} />
            <div className='team--grid'>
                <MixInfoTeam onClick={setTeam} quotes={quotes} team={team} />
            </div>
        </>
    )
}
