import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'
import Phone from '../icons/phone'
import Email from '../icons/email'

export default function ImageContent({title, image, phone, email, subtitle, content, video}) {
    const play = () => {
        var video = document.querySelector('.feature-video');
        video.muted = false;
        if (video.requestFullscreen) {
          video.requestFullscreen()
        } else if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen()
        } else if (video.msRequestFullscreen) {
          video.msRequestFullscreen()
        } else if (video.webkitEnterFullscreen) {
          video.webkitEnterFullscreen()
        }
    }
    useEffect(() => {
        document.addEventListener("fullscreenchange", function() {
          if (!document.fullscreen) {
            var video = document.querySelector('.feature-video');
            if(video) {
                video.muted = true;
            }
          } 
        });
    });
    return (
        <>
        <div className="pl30 pr30">
            <div className="bt1"></div> 
        </div>
        <div className='image--content p30 m-p15 flex h100vh m-wrap m-col-reverse m-mha'>
            <div className="w-50 m-100 flex flex-wrap">
                <div className="max-450 pr30">
                    <p className="h4 m0 mb40 m-mb20">{title}</p>
                </div>
                <div className="w-100 mta pr30">
                    <div className='small-serif max-450 mb40 m-mb20'>{subtitle}</div>
                    <div className="body-copy max-450 m-mb20" dangerouslySetInnerHTML={{'__html' : content}}></div>
                    {( phone ?
                        <>
                            <div className='flex align-center mt20'> 
                                <Phone /><h5 className='small-serif ml10'>Phone</h5>
                            </div>
                            <p className='body-copy'>
                              <a href={"tel:"+phone}>{phone}</a>
                            </p>
                        </>
                        :
                        ''
                    )}
                    {( email ?
                        <>
                            <div className='flex align-center mt20'> 
                                <Email /><h5 className='small-serif ml10'>Email</h5>
                            </div>
                            <p className='body-copy'>
                              <a href={"mailto:"+email}>{email}</a>
                            </p>
                        </>
                        :
                        ''
                    )}
                </div>
            </div>
            <div className="w-50 m-100 bg-leather pos-rel m-ratio-2-1 m-mb20 m-br5 overflow-hidden">
                {(video ?
                <div className='bg-image pointer' role='presentation' onClick={play}>  
                    <div className='play--button'/>
                    <video className="bg-video feature-video pointer-all z-2" muted autoPlay loop><source src={video} type="video/mp4" /></video>
                </div>:
                <GatsbyImage alt='' image={image?.gatsbyImageData} className='bg-image' />
                )}
            </div>
        </div>
        </>
    )
}
