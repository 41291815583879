import React, { memo } from 'react'
import TeamThumbnailGeneric from './team-thumbnail-generic'


export default function Leadership ({team}) {
    return (
        <div className='team--grid'>
            {team.map((teamMember,index) =>{
           
                return <TeamThumbnailGeneric teamMember={teamMember} key={index}/>  
            })}
        </div>
    )
}
