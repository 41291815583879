import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';


export default function TeamThumbnailGeneric({ teamMember, className }) {
    return (
        <div className={'bg-wrapper w100 pt125 re ' + className} >
            <div className={'team--member bg-image z-2     '}> 
                <div className='h100'>
                    
                </div>
                <div className='flex flex-c ju-c h100'>
                    <h3 className='h4 text-center'>
                        {teamMember.name}
                    </h3>
                </div>
                <div className='flex flex-c flex-end h100'>
                    <div>
                <h5 className='small-serif max-325 text-center ma'>{teamMember.companyPosition}</h5>
                </div>
                </div>


            </div>
            <div className='bg-image z-1 r6'>
                <GatsbyImage alt="" className='r6 h100 bg-image ' image={teamMember?.image?.gatsbyImageData} objectPosition='center center' src='../../images/person.png' />
            </div>

        </div>
    );
}


export const query = graphql`
    fragment GenericTeamMember on DatoCmsTeamMember{
        name
            companyPosition
            image {
                gatsbyImageData
            }
            years
            senior  
    }
`